// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-js": () => import("/opt/build/repo/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-the-club-js": () => import("/opt/build/repo/src/pages/the-club.js" /* webpackChunkName: "component---src-pages-the-club-js" */),
  "component---src-pages-the-shop-js": () => import("/opt/build/repo/src/pages/the-shop.js" /* webpackChunkName: "component---src-pages-the-shop-js" */),
  "component---src-pages-visit-us-js": () => import("/opt/build/repo/src/pages/visit-us.js" /* webpackChunkName: "component---src-pages-visit-us-js" */)
}

